import {
  InviteNewMembersToTeamResponse,
  CreateTeamResponse,
  InviteFriendsResponse,
  JoinTeamResponse,
} from './Types'
import httpClient from '../httpClient'
import { AxiosError } from 'axios'

const TeamService = {
  /**
   *
   * @param teamID
   * @param serializedNewMembers the new members list after JSON.stringify has been called on it
   */
  inviteNewMembersToTeam: async function (
    teamID: number,
    serializedNewMembers: string,
    source: string
  ): Promise<InviteNewMembersToTeamResponse> {
    try {
      const response = await httpClient.post(`/companies/${teamID}/invite_members`, {
        newMembersList: serializedNewMembers,
        source,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  updateMemberAccesses: function (teamId, memberId, payload, cb) {
    $.ajax({
      type: 'POST',
      url: `/companies/${teamId}/members/${memberId}`,
      beforeSend: $.rails.CSRFProtection,
      contentType: 'application/json',
      data: JSON.stringify(payload),
      success: function (success) {
        cb({ message: success.message })
      },
      error: function (err) {
        cb(null, {
          message: err.responseJSON ? err.responseJSON.message : 'An error has occurred',
        })
      },
    })
  },

  fetchTeam: function (teamId, cb) {
    $.ajax({
      url: `/companies/${teamId}`,
      type: 'GET',
      beforeSend: $.rails.CSRFProtection,
      success: function (res) {
        cb(res)
      },
      error: function (error) {
        toastr.error(`Failed to fetch team #${teamId}.`)
        console.error(error?.responseText)
        cb(null)
      },
    })
  },

  createTeamSignup: async function (
    teamID: string,
    userId?: number,
    slogan?: string,
    logo_url?: string
  ): Promise<CreateTeamResponse> {
    try {
      const response = await httpClient.post('/companies', {
        name: teamID,
        user_id: userId,
        slogan: slogan,
        logo_url: logo_url,
      })
      return response.data
    } catch (error) {
      console.log(error, 'there is an error with creating team')
      throw error
    }
  },

  inviteFriends: async function (
    emails: ReadonlyArray<string>,
    source
  ): Promise<InviteFriendsResponse> {
    try {
      const response = await httpClient.post('/users/platform_referrals', {
        new_emails: emails,
        source,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  createTeam: function (team, cb) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        type: 'POST',
        url: '/companies',
        beforeSend: $.rails.CSRFProtection,
        data: team,
      })
        .done(function (res) {
          if (undefined != cb) {
            cb(res)
          }
          resolve(res)
        })
        .fail(function (err) {
          reject(err)
        })
    })
  },

  acceptTeamInvitation: function (teamId, invitation_code) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        type: 'POST',
        url: `/companies/${teamId}/accept-invitation`,
        beforeSend: $.rails.CSRFProtection,
        data: { invitation_code },
      })
        .done(function (res) {
          resolve(res)
        })
        .fail(function (err) {
          reject(err.responseJSON ? err.responseJSON.message : 'An error has occurred')
        })
    })
  },

  cancelTeamJoinRequest: function (teamID, userID) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        type: 'DELETE',
        url: `/companies/${teamID}/users/${userID}`,
        beforeSend: $.rails.CSRFProtection,
        success: function (res) {
          resolve(res)
        },
        error: function (err) {
          reject(err.responseJSON ? err.responseJSON.message : 'An error has occurred')
        },
      })
    })
  },

  joinTeam: async function (
    pId: number,
    userId: number,
    source: string
  ): Promise<JoinTeamResponse> {
    try {
      const response = await httpClient.post(`/companies/${pId}/join?user_id=${userId}`, {
        pId,
        userId,
        source,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  updateTeam: function (team, callBack) {
    $.ajax({
      type: 'POST',
      url: `/companies/${team.id}`,
      beforeSend: $.rails.CSRFProtection,
      data: team,
      success: function (res) {
        if (undefined != callBack) {
          callBack(res)
        }
      },
      error: function (res) {
        if (undefined != callBack) {
          console.log(`Error: ${res.statusText}`)
          if (res.responseJSON) {
            callBack(null, res.responseJSON)
          } else {
            callBack(null, {
              message: res.statusText,
              errors: res.responseText,
            })
          }
        }
      },
    })
  },

  leaveTeam: function (newOwnerId, callBack) {
    httpClient
      .post('/companies/leave_team', {
        new_owner_id: newOwnerId,
      })
      .then(res => {
        if (callBack) {
          callBack(res.data, null)
        }
      })
      .catch((error: AxiosError) => {
        if (callBack) {
          callBack(null, error)
        }
      })
  },

  getDomainTeams: function (pDomain, cb) {
    return new Promise(function (resolve, reject) {
      $.ajax({
        type: 'GET',
        url: `/team-domain/${pDomain}`,
        beforeSend: $.rails.CSRFProtection,
        success: function (res) {
          if (undefined != cb) {
            cb(res)
          }
          resolve(res)
        },
        error: function (err) {
          reject(err)
        },
      })
    })
  },

  getPartnerPartIntegratedDesigns: async function getPartnerPartIntegratedDesigns(teamId) {
    try {
      const response = await httpClient.get(`/companies/${teamId}/fetch_partner_part_designs`)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  updateCorporateAccount: function (account, taxId, callback) {
    httpClient
      .post('/public_api/corporate_accounts/update', {
        account,
        taxId,
      })
      .then(res => {
        if (callback) {
          callback(res.data, null)
        }
      })
      .catch((error: AxiosError) => {
        if (callback) {
          callback(null, error)
        }
      })
  },
}

export default TeamService
